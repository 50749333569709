html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: 'The Sans', 'TheSans C5', 'sans-serif';
  font-style: normal;
  font-size: 18px;

  .breadcrumb-row {
    justify-content: center;
    margin: 0 0 8px;
  }

  .article-breadcrumb-row {
    margin: 0;
  }

  .main-container {

    a[target=_blank][href^="http://"]:not([href*="ard-helpdesk.jatosha.com"]):not([href*="hilfe.webapps.prod.daserste.cloud"]):not([href*="hilfe.ard.de"]):not([href*="helpdesk-frontend.dev.daserste.cloud"]):not([href*="localhost:3000"]):not([href*="mailto:"]):after,
    a[target=_blank][href^="https://"]:not([href*="ard-helpdesk.jatosha.com"]):not([href*="hilfe.webapps.prod.daserste.cloud"]):not([href*="hilfe.ard.de"]):not([href*="helpdesk-frontend.dev.daserste.cloud"]):not([href*="localhost:3000"]):not([href*="mailto:"]):after {
      content: "" url('/static/images/externalLink.svg');
      background-size: 28px 28px;
      display: inline-block;
      width: 28px;
      height: 28px;
      position: relative;
      top: 8px;
      overflow: hidden;
      margin-top: -28px;
      margin-right: -5px;
      text-indent: 0px;
    }

    @media screen and (max-width: 481px) {

      a[target=_blank][href^="http://"]:not([href*="ard-helpdesk.jatosha.com"]):not([href*="hilfe.webapps.prod.daserste.cloud"]):not([href*="hilfe.ard.de"]):not([href*="helpdesk-frontend.dev.daserste.cloud"]):not([href*="localhost:3000"]):not([href*="mailto:"]):after,
      a[target=_blank][href^="https://"]:not([href*="ard-helpdesk.jatosha.com"]):not([href*="hilfe.webapps.prod.daserste.cloud"]):not([href*="hilfe.ard.de"]):not([href*="helpdesk-frontend.dev.daserste.cloud"]):not([href*="localhost:3000"]):not([href*="mailto:"]):after {
        content: "" url('/static/images/externalLink.svg');
        background-size: 26px 26px;
        width: 26px;
        height: 26px;
        top: 7px;
      }
    }

    @media (hover: hover) {

      a[target=_blank][href^="http://"]:hover:not([href*="ard-helpdesk.jatosha.com"]):not([href*="hilfe.webapps.prod.daserste.cloud"]):not([href*="hilfe.ard.de"]):not([href*="helpdesk-frontend.dev.daserste.cloud"]):not([href*="localhost:3000"]):not([href*="mailto:"]):after,
      a[target=_blank][href^="https://"]:hover:not([href*="ard-helpdesk.jatosha.com"]):not([href*="hilfe.webapps.prod.daserste.cloud"]):not([href*="hilfe.ard.de"]):not([href*="helpdesk-frontend.dev.daserste.cloud"]):not([href*="localhost:3000"]):not([href*="mailto:"]):after {
        content: "" url('/static/images/externalLinkHover.svg');
      }
    }
  }

}

ul[role='listbox'] {
  z-index: 100;
  position: absolute;
  width: 100%;
  max-width: 750px;
  margin: 0 auto;
  background-color: #fff;

  li {
    >button svg {
      fill: #000;
      color: #000;
    }

    >div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      span {
        display: inline-block;
        max-width: 85%;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      span,
      svg {
        fill: #000;
        color: #000;
      }
    }
  }
}

.article-main-container,
.website-main-container,
.error-404-main-container,
.error-500-main-container {
  margin-top: 98px;
  min-height: calc(100vh - 195px) !important;
}

@media screen and (max-width: 481px) {

  .article-main-container,
  .website-main-container,
  .error-404-main-container,
  .error-500-main-container {
    margin-top: 98px;
    min-height: calc(100vh - 200px) !important;
  }
}

.toolbar {
  >div>div>div>div>button {
    border-radius: 20px;
  }

  button:hover {
    background-color: unset !important;
  }

  button[role='menuitem'] {
    &:hover {
      text-decoration: underline !important;

      svg {
        background-color: none;
      }
    }
  }

  ul li svg {
    margin-right: 10px;
  }

  >div>div>div {
    &::after {
      background-color: #2D91FF;
    }
  }
}

.search-filter-chip-wrapper {
  button {
    >span {
      font-style: normal;
      font-weight: 520;
    }
  }
}

video {
  background-color: #EEF0F1;
}

body.light-theme,
body.dark-theme {
  font-size: 18px;

  video {
    background-color: #EEF0F1;
  }

  h1 {
    font-size: 36px;
    line-height: 120%;
    font-weight: 530;
    text-align: center;
    margin-bottom: 24px;
    padding: 0;
  }

  h2,
  h1.h2 {
    font-size: 27px;
    font-weight: 500;
    line-height: 120%;
    padding: 0;
    text-align: left;
  }

  .article-description>p {
    padding: 0;
  }

  h3 {
    font-size: 27px;
  }

  h4 {
    font-size: 24px;
  }

  h5 {
    font-size: 20px;
    font-weight: 530;
  }

  h6 {
    font-size: 18px;
  }

  @media screen and (max-width: 481px) {
    font-size: 16px;

    h1,
    .article-main-headline {
      font-size: 28px;
    }

    h2 {
      font-size: 24px;
    }

    h3 {
      font-size: 24px;
    }

    h4 {
      font-size: 22px;
    }

    h5 {
      font-size: 18px;
    }

    h6 {
      font-size: 16px;
    }
  }

  button.active {
    &:not([data-type="chip"]):not([data-type="secondary"]):not([data-type="tab"]):not([data-type="square"]) {
      background: transparent !important;
    }
  }

  .container {
    border-bottom: solid 1px rgba(255, 255, 255, 0.5);
    margin-top: 16px;
    padding: 0 8px;

    >a {
      margin-bottom: 12px;
      display: block;
    }
  }

  .small-text {
    font-weight: 400;
    line-height: 120%;
    font-size: 14px;
  }

  a[data-type='primary'],
  a[data-type='secondary'] {
    padding: 0;
    font-weight: 520;
  }
}

body.dark-theme {
  background-color: #000E29;
  color: #FFF;

  & {

    a,
    p,
    h1,
    h2,
    h3,
    h4 {
      text-decoration: none;
    }
  }

  .paragraph {
    .copy-id-h-tag {
      .ard-tooltip {
        background-color: #003480 !important;
        color: #FFF !important;
      }
    }
  }

  video {
    background-color: #243350;
  }

  p {
    color: #FFF;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #FFF;
  }

  a {
    color: #FFF;

    @media (hover: hover) {
      &:hover {
        color: #2D91FF;
      }
    }
  }

  .search-filter-chip-wrapper {
    button {
      background: rgba(255, 255, 255, 0.2);
      border-color: rgba(255, 255, 255, 0.2);
      fill: rgba(255, 255, 255, 0.2);
      color: #fff;
      box-shadow: none;

      >span {
        padding: 0 8px;
      }

      &.active {
        background: #fff !important;
        border-color: #fff !important;
        fill: #fff !important;
        color: #000e29 !important;
        box-shadow: none !important;

        >svg {
          background-color: #fff;
          fill: #000e29 !important;
        }

        >span {
          padding-left: 0;
        }

        @media screen and (min-width: 961px) {
          &:hover {
            color: #fff !important;

            >svg,
            >svg>path {
              background-color: #2D91FF !important;
            }
          }
        }
      }

      @media screen and (min-width: 961px) {
        &:hover {
          background: #2D91FF !important;
          border-color: #2D91FF;
          fill: #2D91FF;
          color: #fff;
          box-shadow: none !important;
        }
      }
    }
  }

  .toolbar {
    button[role='menuitem'] {
      background-color: unset !important;

      &:hover {
        background-color: unset !important;

        >div {
          background-color: unset !important;
        }
      }
    }
  }

  .description {
    color: #FFF;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .chip>a {
    box-shadow: none;
  }

  .theme-icon>svg>path {
    fill: #FFF;
  }

  button.active {
    &:not([data-type="chip"]):not([data-type="secondary"]):not([data-type="tab"]):not([data-type="square"]) {
      color: #FFF !important;
      fill: #FFF !important;
    }
  }

  .cookie-banner {
    background: #243350;
  }

  .small-text {
    opacity: 78%;
  }

}

body.light-theme {
  background-color: #fff;

  & {

    a,
    p,
    h1,
    h2,
    h3,
    h4 {
      text-decoration: none;
    }
  }

  a:hover {
    color: #2D91FF;
  }

  .paragraph {
    .copy-id-h-tag {

      h4,
      h5 {
        a svg {
          fill: #000;

          path {
            color: #000;
            fill: #000;
          }
        }
      }

      &:hover {

        h4,
        h5 {
          cursor: pointer;
        }

        a svg {
          fill: #2D91FF;

          path {
            color: #2D91FF;
            fill: #2D91FF;
          }
        }
      }
    }
  }

  span#search-article-result-count {
    background: rgba(0, 14, 41, 0.2);
    color: rgba(0, 14, 41) !important;
  }

  .search-filter-chip-wrapper {
    button {
      background: rgba(255, 255, 255, 0.20);
      border-color: rgba(255, 255, 255, 0.20);
      fill: rgba(255, 255, 255, 0.20);
      color: #fff;
      box-shadow: none;

      >span {
        padding: 0 8px;
      }

      &.active {
        background: #fff !important;
        border-color: #fff !important;
        fill: #fff !important;
        color: rgba(0, 14, 41) !important;
        box-shadow: none !important;

        >svg {
          background-color: #fff;
          fill: #000e29 !important;
        }

        >span {
          padding-left: 0;
        }

        @media screen and (min-width: 961px) {
          &:hover {
            color: #fff !important;

            >svg,
            >svg>path {
              background-color: #2D91FF !important;
            }
          }
        }
      }

      @media screen and (min-width: 961px) {
        &:hover {
          background: #2D91FF !important;
          border-color: #2D91FF;
          fill: #2D91FF;
          color: #fff;
          box-shadow: none !important;
        }
      }
    }
  }

  .toolbar {
    button[role='menuitem'] {
      background-color: unset !important;

      &:hover {
        background-color: unset !important;

        >div {
          background-color: unset !important;
        }
      }

      &:hover {
        color: #2D91FF !important;
        fill: #2D91FF !important;

        svg {
          path {
            fill: #2D91FF !important;
          }
        }
      }
    }

    >div>div>div {
      &::after {
        background-color: #2D91FF;
      }

      //box-shadow: unset;
    }
  }

  .theme-icon>svg>path {
    fill: #000E29;
  }

  .container {
    border-color: #000E29;
  }

  button.active,
  button.active:hover {
    &:not([data-type="chip"]):not([data-type="secondary"]):not([data-type="tab"]):not([data-type="square"]) {
      color: #000E29 !important;
      fill: #000E29 !important;

      @media (hover: hover) {
        &:hover {
          color: #FFF !important;
        }
      }
    }
  }

  @media (hover: hover) {
    button:hover:not([data-type="chip"]):not([data-type="secondary"]):not([data-type="tab"]):not([data-type="square"])~div>div>div>p {
      color: #FFF;
    }

    button:hover:is([data-type="tab"])~div>div>div>p {
      color: #000E29;
    }
  }

  .cookie-banner {
    background: #DEE1E4;

    a {
      color: #000E29;
    }

    a:hover {
      color: #2D91FF;
    }
  }

  .small-text {
    opacity: 50%;
  }
}

.paragraph,
.paragraph p {
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.primary-link {
  a {
    color: #0071EE !important;
  }

  a:hover {
    color: #2D91FF !important;
  }
}